/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, ButtonGroup, IconButton, Container, TextField, Autocomplete } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Typography from "@mui/material/Typography";
import ModuleNav from "../ModuleNav";

import navconfig from "./navconfig";
import Yup from "../CustomYupValidation";
import { useGetEventsQuery, useAddNewEventMutation, useUpdateEventMutation, useDeleteEventMutation, useGetLabelsQuery } from "../../services/janApi";
import { eventActionsStyle, GridContainer, headerStyle, rowHeader, rowStyle } from "../Configurations/styles";

const EventsList = () => {
    const [sortParams, setSortParams] = useState({ column: "name", direction: 1 });
    const { labels, isLoadingLabels, refetchLabels } = useGetLabelsQuery(undefined, {
        selectFromResult: ({ data, isLoading, refetch }) => ({
            labels: data === undefined ? [] : Object.values(data),
            isLoading,
            refetch,
        }),
    });

    const { data, isLoading, refetch } = useGetEventsQuery(undefined, {
        selectFromResult: ({ data, isLoading, refetch }) => ({
            data:
                data === undefined
                    ? []
                    : Object.values(data).sort((a, b) => {
                          if (a[sortParams.column] && b[sortParams.column]) {
                              const nameA = a[sortParams.column].toString().toUpperCase();
                              const nameB = b[sortParams.column].toString().toUpperCase();
                              if (nameA < nameB) {
                                  return -sortParams.direction;
                              }
                              if (nameA > nameB) {
                                  return sortParams.direction;
                              }
                          }
                          return 0;
                      }),
            isLoading,
            refetch,
        }),
    });
    const [editableRowId, setEditableRowId] = useState(0);
    const [confirmDeleteId, setConfirmDeleteId] = useState(0);
    const [filterEvent, setFilterEvent] = useState("");
    const [addNewEvent] = useAddNewEventMutation();
    const [updateEvent] = useUpdateEventMutation();
    const [deleteEvent] = useDeleteEventMutation();
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        events: Yup.array().of(
            Yup.object().shape({
                name: Yup.string()
                    .uniqueIn(data, t("events.form.eventNameIsUsed"))
                    .min(2, t("events.form.eventNameIsShort"))
                    .required(t("events.form.eventNameIsRequired"))
                    .matches(/^[a-zA-Z0-9_-]+$/, t("events.form.siteNameOnlyAlphabetsAndNumbers")),
            })
        ),
    });

    const { register, watch, formState, control, reset, getValues, setValue } = useForm({
        mode: "onChange",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            events: data ? Object.values(data) : [],
        },
    }); // initialise the hook
    const { errors, isValid } = formState;
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: "events",
    });

    const defaultProps = {
        options: labels,
        getOptionLabel: (option) => option.name ?? "",
        isOptionEqualToValue: (option, value) => true,
    };

    const onCancel = (eventId, eventIndex) => {
        if (eventId === 0) {
            remove(eventIndex);
        } else {
            update(eventIndex, Object.values(data)[eventIndex]);
            setEditableRowId(0);
        }
    };

    const onSave = (eventIndex) => {
        if (!isValid) {
            return false;
        }

        const currentEvent = watch(`events.${eventIndex}`);

        if (currentEvent.id > 0) {
            updateEvent({ id: currentEvent.id, values: currentEvent })
                .unwrap()
                .then(() => {
                    toast.success(t("events.form.eventRecorded"));
                    update(eventIndex, currentEvent);
                    setEditableRowId(0);
                })
                .catch((error) => console.log("error", error));
        } else {
            addNewEvent(currentEvent)
                .unwrap()
                .then((payload) => {
                    toast.success(t("events.form.eventRecorded"));
                    currentEvent.id = payload.id;
                    update(eventIndex, currentEvent);
                })
                .catch((error) => console.log("error", error));
        }

        return false;
    };

    const onDelete = (eventId, eventIndex) => {
        deleteEvent({ id: eventId })
            .unwrap()
            .then(() => {
                toast.success(t("events.form.eventDeleted"));
                remove(eventIndex);
                setConfirmDeleteId(0);
            })
            .catch((error) => console.log("error", error));
    };

    const displaySortIcon = (columnName) => {
        if (columnName === sortParams.column) {
            return (
                <IconButton
                    onClick={() =>
                        setSortParams({
                            column: columnName,
                            direction: -sortParams.direction,
                        })
                    }
                >
                    {sortParams.direction === 1 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </IconButton>
            );
        }
        return (
            <IconButton onClick={() => setSortParams({ column: columnName, direction: 1 })}>
                <ArrowDownwardIcon color="disabled" />
            </IconButton>
        );
    };

    useEffect(() => {
        reset({ events: data ? Object.values(data) : [] });
        if (filterEvent && data) {
            reset({ events: data.filter((event) => event.label_name && event.label_name.toLowerCase().indexOf(filterEvent.toLowerCase()) !== -1) });
        }
    }, [isLoading, sortParams, filterEvent]);

    return (
        <div>
            <ModuleNav title={navconfig.title} tabs={navconfig.tabs} icon={navconfig.icon} />
            <Container>
                {isLoading && <Box>loading</Box>}
                <Grid sx={{ display: "flex", flexDirection: "column", float: "right", width: "30%" }}>
                    <TextField
                        sx={{ width: "100%" }}
                        autoFocus
                        margin="dense"
                        id="test_url"
                        label={t("common.filter_by_label")}
                        variant="standard"
                        value={filterEvent}
                        onChange={(e) => {
                            setFilterEvent(e.target.value);
                        }}
                    />
                </Grid>

                {!isLoading && !isLoadingLabels && (
                    <form>
                        <div>
                            <Box sx={GridContainer}>
                                <Grid container sx={headerStyle} direction="row">
                                    <Grid item sx={rowHeader} xs={3} md={3}>
                                        <Grid item>{displaySortIcon("name")}</Grid>
                                        <Grid item>{t("common.name")}</Grid>
                                    </Grid>
                                    <Grid item sx={rowHeader} xs={3} md={2}>
                                        <Grid item>{displaySortIcon("label")}</Grid>
                                        <Grid item>{t("common.label")}</Grid>
                                    </Grid>
                                    <Grid item sx={rowHeader} xs={3} md={3}>
                                        <Grid item>{t("common.used_by_sites")}</Grid>
                                    </Grid>
                                    <Grid item xs={3} md={2} sx={eventActionsStyle}>
                                        {t("configuration.actions")}
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={GridContainer}>
                                {fields.map((oEvent, index) => {
                                    const eventId = watch(`events.${index}.id`);
                                    const eventLabelId = watch(`events.${index}.label_id`);
                                    const eventLabelName = watch(`events.${index}.label_name`);
                                    const labelValue = { id: eventLabelId ?? "", name: eventLabelName ?? "" };
                                    const eventNameError = errors?.events?.[index]?.name?.message;
                                    const OddClass = index % 2 === 0 ? "even" : "odd";
                                    const autoCompId = `auto_${eventId}`;
                                    return (
                                        <React.Fragment key={eventId}>
                                            <Grid container key={eventId} sx={rowStyle} className={OddClass}>
                                                {editableRowId !== eventId && (
                                                    <>
                                                        <Grid item xs={3} md={3}>
                                                            {oEvent.name}
                                                        </Grid>
                                                        <Grid item xs={3} md={2}>
                                                            {oEvent.label_name}
                                                        </Grid>
                                                        <Grid item xs={3} md={3} sx={{ fontStyle: "italic", fontSize: "12px" }}>
                                                            {oEvent.site_names}
                                                        </Grid>
                                                    </>
                                                )}
                                                {editableRowId === eventId && (
                                                    <Grid item xs={3} md={9}>
                                                        <Grid container>
                                                            <TextField fullWidth label={t("common.name")} control={control} {...register(`events.${index}.name`)} error={Boolean(eventNameError)} helperText={eventNameError} />
                                                            <Autocomplete
                                                                fullWidth
                                                                {...defaultProps}
                                                                id={autoCompId}
                                                                value={labelValue}
                                                                onChange={(e, newValue) => {
                                                                    if (newValue) {
                                                                        setValue(`events.${index}.label_id`, newValue.id ?? null);
                                                                        setValue(`events.${index}.label_name`, newValue.name ?? null);
                                                                    }
                                                                }}
                                                                renderInput={(params) => <TextField {...params} label={t("common.label")} variant="standard" />}
                                                            />

                                                            <Typography
                                                                sx={{
                                                                    fontStyle: "italic",
                                                                    textDecoration: "underline",
                                                                    fontSize: "12px",
                                                                    marginRight: "10px",
                                                                }}
                                                                item
                                                            >
                                                                {t("common.used_by")}
                                                            </Typography>
                                                            <Typography
                                                                item
                                                                sx={{
                                                                    fontStyle: "italic",
                                                                    fontSize: "12px",
                                                                    marginRight: "10px",
                                                                }}
                                                            >
                                                                {oEvent.site_names}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                <Grid sx={eventActionsStyle} item xs={3} md={2}>
                                                    {editableRowId !== eventId && (
                                                        <IconButton color="primary" onClick={() => setEditableRowId(eventId)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId === eventId && (
                                                        <IconButton onClick={() => onCancel(eventId, index)}>
                                                            <CancelIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId === eventId && (
                                                        <IconButton color="primary" onClick={() => onSave(index)}>
                                                            <SaveIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId !== eventId && eventId !== confirmDeleteId && (
                                                        <IconButton color="error" onClick={() => setConfirmDeleteId(eventId)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                    {confirmDeleteId === eventId && eventId !== 0 && (
                                                        <ButtonGroup size="small" aria-label="small button group">
                                                            <Button variant="outlined" onClick={() => setConfirmDeleteId(0)}>
                                                                {t("common.cancel")}
                                                            </Button>
                                                            <Button variant="outlined" color="error" onClick={() => onDelete(eventId, index)}>
                                                                {t("common.confirm")}
                                                            </Button>
                                                        </ButtonGroup>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    );
                                })}
                                <Grid>
                                    <Grid sx={rowStyle} key="actions_key_btn" item xs={12} md={12}>
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                append({
                                                    id: 0,
                                                    name: "",
                                                })
                                            }
                                        >
                                            {t("events.form.eventAdd")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </form>
                )}
            </Container>
        </div>
    );
};

export default EventsList;
